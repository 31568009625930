<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['รายงานแพ้ชนะเกม', 'แพ้-ชนะ ค่ายเกม']"
    />
    <platform-nav name="แพ้-ชนะ ค่ายเกม"></platform-nav>
    <div class="card border-primary mb-2" style="width: 700px">
      <h6 class="card-header border-primary bg-primary text-white p-2">
        ตัวเลือกการค้นหา
      </h6>
      <div class="card-body">
        <div class="d-flex mb-2">
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="fromDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <span class="mx-2 my-auto">ถึง</span>
          <b-form-datepicker
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="th"
            v-model="toDate"
            placeholder="เลือกวัน"
            label-no-date-selected="ยังไม่เลือก"
            today-button
            reset-button
            close-button
            label-close-button="ปิด"
            label-reset-button="รีเซ็ต"
            label-today="วันนี้"
            label-today-button="วันนี้"
          ></b-form-datepicker>
          <button class="btn btn-primary ml-2" @click="loadData">ค้นหา</button>
        </div>

        <div class="d-flex">
          <button
            v-for="(item, index) in searchDateButtons"
            :key="index"
            class="btn btn-sm btn-warning mr-2"
            @click="setDateRange(item.key)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive mt-3" v-show="!isLoading">
      <table
        class="
          table table-sm table-bordered table-striped table-dark table-white
          report
        "
      >
        <thead>
          <tr>
            <th rowspan="2">
              <div class="d-flex flex-column">
                <span>ชื่อแพลตฟอร์ม</span>
                <div class="d-flex justify-content-center">
                  <input
                    id="showPlatformName"
                    type="checkbox"
                    class="cursor-pointer mr-1 my-auto"
                    v-model="showPlatformName"
                  />
                  <label for="showPlatformName" class="my-auto">แสดงชื่อ</label>
                </div>
              </div>
            </th>
            <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
            <th colspan="4" class="br-white">สมาชิก</th>
            <th colspan="4" class="br-white">
              {{ platform.code }}
            </th>
            <th colspan="4">บริษัท</th>
          </tr>
          <tr>
            <th>ยอดส่งออก</th>
            <th>ชนะ</th>
            <th>affiliate</th>
            <th class="br-white">รวม</th>
            <th>ถือหุ้น</th>
            <th>ชนะ</th>
            <th>affiliate</th>
            <th class="br-white">รวม</th>
            <th>ถือหุ้น</th>
            <th>ชนะ</th>
            <th>affiliate</th>
            <th>รวม</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, gameProductIndex) in gameProducts">
            <tr :key="'mtType' + gameProductIndex" v-if="showPlatformName">
              <td colspan="2" class="br-white"></td>
              <td colspan="4" class="br-white"></td>
              <td colspan="4" class="br-white"></td>
              <td colspan="4" class="br-white"></td>
            </tr>
            <tr
              :key="'header' + gameProductIndex"
              :class="{ 'bg-green3': showPlatformName }"
            >
              <td class="text-left">
                <span>{{ item.name }}</span>
              </td>

              <td class="br-white" :class="{ 'bg-blue': !showPlatformName }">
                {{ util.formatMoney(item.sumBetResultByType.sumBuyAmount) }}
              </td>

              <!-- member -->
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.member.sumBuyAmount > 0,
                    negative: item.sumBetResultByType.member.sumBuyAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.member.sumBuyAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.member.sumWinAmount > 0,
                    negative: item.sumBetResultByType.member.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.member.sumWinAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive:
                      item.sumBetResultByType.member.sumAffiliateAmount > 0,
                    negative:
                      item.sumBetResultByType.member.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.member.sumAffiliateAmount
                    )
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: item.sumBetResultByType.member.sumSumAmount > 0,
                    negative: item.sumBetResultByType.member.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.member.sumSumAmount
                    )
                  }}</span
                >
              </td>

              <!-- me -->
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.me.sumShareAmount > 0,
                    negative: item.sumBetResultByType.me.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(item.sumBetResultByType.me.sumShareAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.me.sumWinAmount > 0,
                    negative: item.sumBetResultByType.me.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(item.sumBetResultByType.me.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.me.sumAffiliateAmount > 0,
                    negative: item.sumBetResultByType.me.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.me.sumAffiliateAmount
                    )
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: item.sumBetResultByType.me.sumSumAmount > 0,
                    negative: item.sumBetResultByType.me.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(item.sumBetResultByType.me.sumSumAmount)
                  }}</span
                >
              </td>

              <!-- company -->
              <td>
                <span
                  :class="{
                    positive:
                      item.sumBetResultByType.company.sumShareAmount > 0,
                    negative:
                      item.sumBetResultByType.company.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.company.sumShareAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.company.sumWinAmount > 0,
                    negative: item.sumBetResultByType.company.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.company.sumWinAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive:
                      item.sumBetResultByType.company.sumAffiliateAmount > 0,
                    negative:
                      item.sumBetResultByType.company.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.company.sumAffiliateAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: item.sumBetResultByType.company.sumSumAmount > 0,
                    negative: item.sumBetResultByType.company.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      item.sumBetResultByType.company.sumSumAmount
                    )
                  }}</span
                >
              </td>
            </tr>
            <template v-if="showPlatformName">
              <tr
                :key="'body' + gameProductIndex + index2"
                v-for="(betResult, index2) in item.betResults"
              >
                <td class="text-left">
                  <router-link
                    class="mr-1"
                    :to="{
                      name: 'ReportGamePlatformByDate',
                      params: { platformId: betResult.platformId },
                      query: {
                        typeId: item.id,
                        fromDate: fromDate,
                        toDate: toDate,
                      },
                    }"
                    >{{ betResult.code }}</router-link
                  ><span v-if="showPlatformName">({{ betResult.name }})</span>
                </td>
                <td class="bg-blue br-white">
                  {{ util.formatMoney(betResult.buyAmount) }}
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.member.buyAmount > 0,
                      negative: betResult.member.buyAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.member.buyAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.member.winAmount > 0,
                      negative: betResult.member.winAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.member.winAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.member.affiliateAmount > 0,
                      negative: betResult.member.affiliateAmount < 0,
                    }"
                    >{{
                      util.formatMoney(betResult.member.affiliateAmount)
                    }}</span
                  >
                </td>
                <td class="br-white">
                  <span
                    :class="{
                      positive: betResult.member.sumAmount > 0,
                      negative: betResult.member.sumAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.member.sumAmount) }}</span
                  >
                </td>

                <!-- me -->
                <td>
                  <span
                    :class="{
                      positive: betResult.me.shareAmount > 0,
                    }"
                    >{{ util.formatMoney(betResult.me.shareAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.me.winAmount > 0,
                      negative: betResult.me.winAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.me.winAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.me.affiliateAmount > 0,
                      negative: betResult.me.affiliateAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.me.affiliateAmount) }}</span
                  >
                </td>
                <td class="br-white">
                  <span
                    :class="{
                      positive: betResult.me.sumAmount > 0,
                      negative: betResult.me.sumAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.me.sumAmount) }}</span
                  >
                </td>

                <!-- company -->
                <td>
                  <span
                    :class="{
                      positive: betResult.company.shareAmount > 0,
                      negative: betResult.company.shareAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.company.shareAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.company.winAmount > 0,
                      negative: betResult.company.winAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.company.winAmount) }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.company.affiliateAmount > 0,
                      negative: betResult.company.affiliateAmount < 0,
                    }"
                    >{{
                      util.formatMoney(betResult.company.affiliateAmount)
                    }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{
                      positive: betResult.company.sumAmount > 0,
                      negative: betResult.company.sumAmount < 0,
                    }"
                    >{{ util.formatMoney(betResult.company.sumAmount) }}</span
                  >
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <div class="mt-4" v-if="!isLoading">
      <h4>รวมทั้งหมด</h4>
      <div class="table-responsive" v-if="!isLoading">
        <table
          class="
            table table-sm table-bordered table-striped table-dark table-white
            report
          "
        >
          <thead>
            <tr>
              <th rowspan="2">
                <div class="d-flex flex-column">
                  <span>ชื่อแพลตฟอร์ม</span>
                  <div class="d-flex justify-content-center">
                    <input
                      id="showAllPlatformName"
                      type="checkbox"
                      class="cursor-pointer mr-1 my-auto"
                      v-model="showAllPlatformName"
                    />
                    <label for="showAllPlatformName" class="my-auto"
                      >แสดงชื่อ</label
                    >
                  </div>
                </div>
              </th>
              <th rowspan="2" class="br-white">ยอดทั้งหมด</th>
              <th colspan="4" class="br-white">สมาชิก</th>
              <th colspan="4" class="br-white">
                {{ platform.code }}
              </th>
              <th colspan="4">บริษัท</th>
            </tr>
            <tr>
              <th>ยอดส่งออก</th>
              <th>ชนะ</th>
              <th>affiliate</th>
              <th class="br-white">รวม</th>
              <th>ถือหุ้น</th>
              <th>ชนะ</th>
              <th>affiliate</th>
              <th class="br-white">รวม</th>
              <th>ถือหุ้น</th>
              <th>ชนะ</th>
              <th>affiliate</th>
              <th>รวม</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(betResult, index) in betResultByUsers" :key="index">
              <td class="text-left">
                <router-link
                  v-if="betResult.level != 'MEMBER'"
                  :to="{
                    name: 'ReportGameProductPlatformByDate',
                    params: { platformId: betResult.platformId },
                    query: {
                      fromDate: fromDate,
                      toDate: toDate,
                    },
                  }"
                  >{{ betResult.code }}</router-link
                >
                <span v-if="betResult.level == 'MEMBER'">{{
                  betResult.code
                }}</span>
                <span v-if="showAllPlatformName" class="ml-1"
                  >({{ betResult.name }})</span
                >
              </td>
              <td class="bg-blue br-white">
                {{ util.formatMoney(betResult.buyAmount) }}
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.member.buyAmount > 0,
                    negative: betResult.member.buyAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.buyAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.member.winAmount > 0,
                    negative: betResult.member.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.member.affiliateAmount > 0,
                    negative: betResult.member.affiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(betResult.member.affiliateAmount)
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: betResult.member.sumAmount > 0,
                    negative: betResult.member.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.member.sumAmount) }}</span
                >
              </td>

              <!-- me -->
              <td>
                <span
                  :class="{
                    positive: betResult.me.shareAmount > 0,
                  }"
                  >{{ util.formatMoney(betResult.me.shareAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.me.winAmount > 0,
                    negative: betResult.me.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.me.affiliateAmount > 0,
                    negative: betResult.me.affiliateAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.affiliateAmount) }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: betResult.me.sumAmount > 0,
                    negative: betResult.me.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.me.sumAmount) }}</span
                >
              </td>

              <!-- company -->
              <td>
                <span
                  :class="{
                    positive: betResult.company.shareAmount > 0,
                    negative: betResult.company.shareAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.shareAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.company.winAmount > 0,
                    negative: betResult.company.winAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.winAmount) }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.company.affiliateAmount > 0,
                    negative: betResult.company.affiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(betResult.company.affiliateAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: betResult.company.sumAmount > 0,
                    negative: betResult.company.sumAmount < 0,
                  }"
                  >{{ util.formatMoney(betResult.company.sumAmount) }}</span
                >
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-left">รวม:</td>

              <!-- member -->
              <td class="bg-blue br-white">
                {{ util.formatMoney(sumBetResultByUser.sumBuyAmount) }}
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumBuyAmount > 0,
                    negative: sumBetResultByUser.member.sumBuyAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumBuyAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumWinAmount > 0,
                    negative: sumBetResultByUser.member.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumAffiliateAmount > 0,
                    negative: sumBetResultByUser.member.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      sumBetResultByUser.member.sumAffiliateAmount
                    )
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: sumBetResultByUser.member.sumSumAmount > 0,
                    negative: sumBetResultByUser.member.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.member.sumSumAmount)
                  }}</span
                >
              </td>

              <!-- me -->
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumShareAmount > 0,
                    negative: sumBetResultByUser.me.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumShareAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumWinAmount > 0,
                    negative: sumBetResultByUser.me.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumAffiliateAmount > 0,
                    negative: sumBetResultByUser.me.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumAffiliateAmount)
                  }}</span
                >
              </td>
              <td class="br-white">
                <span
                  :class="{
                    positive: sumBetResultByUser.me.sumSumAmount > 0,
                    negative: sumBetResultByUser.me.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.me.sumSumAmount)
                  }}</span
                >
              </td>

              <!-- company -->
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumShareAmount > 0,
                    negative: sumBetResultByUser.company.sumShareAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumShareAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumWinAmount > 0,
                    negative: sumBetResultByUser.company.sumWinAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumWinAmount)
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumAffiliateAmount > 0,
                    negative: sumBetResultByUser.company.sumAffiliateAmount < 0,
                  }"
                  >{{
                    util.formatMoney(
                      sumBetResultByUser.company.sumAffiliateAmount
                    )
                  }}</span
                >
              </td>
              <td>
                <span
                  :class="{
                    positive: sumBetResultByUser.company.sumSumAmount > 0,
                    negative: sumBetResultByUser.company.sumSumAmount < 0,
                  }"
                  >{{
                    util.formatMoney(sumBetResultByUser.company.sumSumAmount)
                  }}</span
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllPlatformName: false,
      showPlatformName: false,
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      betResultByUsers: [],
      sumBetResultByUser: {},
      gameProducts: [],
      isLoading: true,
      platform: {},
      searchDateButtons: [
        {
          name: "วันนี้",
          key: "TODAY",
        },
        {
          name: "เมื่อวาน",
          key: "YESTERDAY",
        },
        {
          name: "สัปดาห์นี้",
          key: "WEEK",
        },
        {
          name: "สัปดาห์ที่แล้ว",
          key: "LASTWEEK",
        },
        {
          name: "เดือนนี้",
          key: "MONTH",
        },
        {
          name: "เดือนที่แล้ว",
          key: "LASTMONTH",
        },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    filterGameByProductId(productId) {
      return this.games?.filter((item) => item.gameProductId == productId)
    },
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadData()
    },
    async loadData() {
      this.isLoading = true

      try {
        let res = await this.axios({
          method: "get",
          url: "report/game-product/bet",
          params: {
            startTime: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            endTime: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            platformId: this.$route.params.platformId,
          },
        })

        let data = res.data.data

        this.makeReport(data)

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    makeReport(data) {
      data.gameProducts?.forEach((item) => {
        let betResults = data.reportGameProductBets?.filter(
          (item2) => item2.gameProductId == item.id
        )

        if (!betResults || betResults.length == 0) {
          return
        }

        item.betResults = []

        let sumBetResultByType = {
          sumBuyAmount: 0.0,
          member: {
            sumBuyAmount: 0.0,
            sumWinAmount: 0.0,
            sumAffiliateAmount: 0.0,
            sumSumAmount: 0.0,
          },
          me: {
            sumShareAmount: 0.0,
            sumWinAmount: 0.0,
            sumAffiliateAmount: 0.0,
            sumSumAmount: 0.0,
          },
          company: {
            sumShareAmount: 0.0,
            sumWinAmount: 0.0,
            sumAffiliateAmount: 0.0,
            sumSumAmount: 0.0,
          },
        }

        betResults.forEach((betResult) => {
          betResult.buyAmount = betResult.stakeAmount

          betResult.me = {
            shareAmount: parseFloat(betResult.stakeReceive),
            winAmount: parseFloat(betResult.payoutReceive) * -1,
            affiliateAmount: parseFloat(betResult.affiliateReceive) * -1,
          }

          betResult.me.sumAmount =
            betResult.me.shareAmount +
            betResult.me.winAmount +
            betResult.me.affiliateAmount

          betResult.member = {
            buyAmount: parseFloat(betResult.stakeRemaining) * -1,
            winAmount: parseFloat(betResult.payoutRemaining),
            affiliateAmount: parseFloat(betResult.affiliateRemaining),
          }

          betResult.member.sumAmount =
            betResult.member.buyAmount +
            betResult.member.affiliateAmount +
            betResult.member.winAmount

          betResult.company = {
            shareAmount:
              (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
            winAmount:
              (betResult.member.winAmount + betResult.me.winAmount) * -1,
            affiliateAmount:
              (betResult.member.affiliateAmount +
                betResult.me.affiliateAmount) *
              -1,
          }

          betResult.company.sumAmount =
            parseFloat(betResult.company.shareAmount) +
            parseFloat(betResult.company.winAmount) +
            parseFloat(betResult.company.affiliateAmount)

          sumBetResultByType.sumBuyAmount += parseFloat(betResult.stakeAmount)

          sumBetResultByType.member.sumBuyAmount += betResult.member.buyAmount
          sumBetResultByType.member.sumWinAmount += betResult.member.winAmount
          sumBetResultByType.member.sumAffiliateAmount +=
            betResult.member.affiliateAmount
          sumBetResultByType.member.sumSumAmount += betResult.member.sumAmount

          sumBetResultByType.me.sumShareAmount += betResult.me.shareAmount
          sumBetResultByType.me.sumWinAmount += betResult.me.winAmount
          sumBetResultByType.me.sumAffiliateAmount +=
            betResult.me.affiliateAmount
          sumBetResultByType.me.sumSumAmount += betResult.me.sumAmount

          sumBetResultByType.company.sumShareAmount +=
            betResult.company.shareAmount
          sumBetResultByType.company.sumWinAmount += betResult.company.winAmount
          sumBetResultByType.company.sumAffiliateAmount +=
            betResult.company.affiliateAmount
          sumBetResultByType.company.sumSumAmount += betResult.company.sumAmount

          item.betResults.push(betResult)
        })

        item.sumBetResultByType = sumBetResultByType
      })

      this.gameProducts = data.gameProducts?.filter((item) => item.betResults)

      let sumBetResultByUser = {
        sumBuyAmount: 0.0,
        member: {
          sumBuyAmount: 0.0,
          sumWinAmount: 0.0,
          sumAffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
        me: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumAffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
        company: {
          sumShareAmount: 0.0,
          sumWinAmount: 0.0,
          sumAffiliateAmount: 0.0,
          sumSumAmount: 0.0,
        },
      }

      data.reportGameProductBetByUser?.forEach((betResult) => {
        betResult.buyAmount = betResult.stakeAmount

        betResult.me = {
          shareAmount: parseFloat(betResult.stakeReceive),
          winAmount: parseFloat(betResult.payoutReceive) * -1,
          affiliateAmount: parseFloat(betResult.affiliateReceive) * -1,
        }

        betResult.me.sumAmount =
          betResult.me.shareAmount +
          betResult.me.winAmount +
          betResult.me.affiliateAmount

        betResult.member = {
          buyAmount: parseFloat(betResult.stakeRemaining) * -1,
          winAmount: parseFloat(betResult.payoutRemaining),
          affiliateAmount: parseFloat(betResult.affiliateRemaining),
        }

        betResult.member.sumAmount =
          betResult.member.buyAmount +
          betResult.member.affiliateAmount +
          betResult.member.winAmount

        betResult.company = {
          shareAmount:
            (betResult.member.buyAmount + betResult.me.shareAmount) * -1,
          winAmount: (betResult.member.winAmount + betResult.me.winAmount) * -1,
          affiliateAmount:
            (betResult.member.affiliateAmount + betResult.me.affiliateAmount) *
            -1,
        }

        betResult.company.sumAmount =
          parseFloat(betResult.company.shareAmount) +
          parseFloat(betResult.company.winAmount) +
          parseFloat(betResult.company.affiliateAmount)

        sumBetResultByUser.sumBuyAmount += parseFloat(betResult.stakeAmount)

        sumBetResultByUser.member.sumBuyAmount += betResult.member.buyAmount
        sumBetResultByUser.member.sumWinAmount += betResult.member.winAmount
        sumBetResultByUser.member.sumAffiliateAmount +=
          betResult.member.affiliateAmount
        sumBetResultByUser.member.sumSumAmount += betResult.member.sumAmount

        sumBetResultByUser.me.sumShareAmount += betResult.me.shareAmount
        sumBetResultByUser.me.sumWinAmount += betResult.me.winAmount
        sumBetResultByUser.me.sumAffiliateAmount += betResult.me.affiliateAmount
        sumBetResultByUser.me.sumSumAmount += betResult.me.sumAmount

        sumBetResultByUser.company.sumShareAmount +=
          betResult.company.shareAmount
        sumBetResultByUser.company.sumWinAmount += betResult.company.winAmount
        sumBetResultByUser.company.sumAffiliateAmount +=
          betResult.company.affiliateAmount
        sumBetResultByUser.company.sumSumAmount += betResult.company.sumAmount
      })

      this.betResultByUsers = data.reportGameProductBetByUser
      this.sumBetResultByUser = sumBetResultByUser
      this.platform = data.platform
    },
  },
}
</script>